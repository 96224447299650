import React, { Component } from 'react'

import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'
import SortingTable from './SortingTable'

const USERS_QUERY = gql`
  query usersList($storeCode: ID!, $userCode: ID, $name: String, $staffName: String, $tabletOnly: Boolean ) {
    store(code: $storeCode ) {
      users(userCode: $userCode, name: $name, staffName: $staffName, tabletOnly: $tabletOnly){
        id
        lastName
        firstName
        userCode
        schoolName
        gradeCode
        tabletOnly
        staffName
        tabletOnly
        lastAccessedAt
        midSchDetail {
          currentModeId
        }
      }
    }
  }
`

class UserSearch extends Component {
  state = {
    users: [],
    storeCode: this.props.storeCode,
    id: this.props.id,
    userCode: '',
    storeName: '',
    staffName: '',
    tabletOnly: false,
    isLoading: false
  }

  render () {
    return (
      <>
        <Box my={5} mx={2}>
          <Typography variant="h6" component="h1" gutterBottom>
            生徒検索
          </Typography>
          <p>生徒氏名、生徒コード（半角数字11桁）、担当者名で検索できます。すべて空欄で店舗内の利用者を全件取得します。</p>
          <form autoComplete="off">
            <TextField
              id="name"
              label="生徒氏名"
              type="text"
              onChange={e => this.setState({ name: e.target.value })}
              margin="normal"
            />
            {` `}
            <TextField
              id="userCode"
              label="生徒コード"
              type="text"
              inputProps={{
                maxLength: 11,
                minLength: 11
              }}
              onChange={e => this.setState({ userCode: e.target.value })}
              margin="normal"
            />
            {` `}
            <TextField
              id="staffName"
              label="担当者名"
              type="text"
              onChange={e => this.setState({ staffName: e.target.value })}
              margin="normal"
            />
            {` `}
            <Fab size="large" variant="extended" color="primary" onClick={() => this._executeSearch()}>
              　検索する　
            </Fab>
          </form>
        </Box>
        <Box my={10} mx={2}>
          <Typography variant="h6" component="h1" gutterBottom>
            検索結果
          </Typography>
          {this.state.isLoading ? <CircularProgress /> : ''}
          {this.state.users.length ? (
            <SortingTable data={this.state.users} storeCode={this.state.storeCode} />
            ) : (
            <p>検索結果は0件です。</p>
            )
          }
        </Box>
      </>
    )
  }

  _executeSearch = async () => {
    const { storeCode, userCode, name, staffName, tabletOnly } = this.state
    this.setState({ users: []})
    this.setState({ isLoading: true })
    const result = await this.props.client.query({
      query: USERS_QUERY,
      variables: {
        storeCode,
        userCode,
        name,
        staffName,
        tabletOnly
      }
    })
    const users = result.data.store.users
    // console.log(users)
    this.setState({ isLoading: false })
    this.setState({ users })
  }

}

export default withApollo(UserSearch)
